var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{staticClass:"form-box"},[_c('b-container',{staticClass:"container-box"},[_c('b-row',{staticClass:"no-gutters"},[_c('b-col',[_c('h1',{staticClass:"font-weight-bold header-main text-uppercase mb-3"},[_vm._v(" แก้ไขคำถามที่พบบ่อย"+_vm._s(_vm.faqTypeName)+" ")])])],1),(_vm.$isLoading)?_c('div',{staticClass:"bg-white p-3"},[_c('b-row',{staticClass:"pl-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"panel"},[_c('b-form-checkbox',{attrs:{"size":"lg","id":"sameLang"},on:{"change":_vm.useSameLanguage},model:{value:(_vm.form.faq.isSameLanguage),callback:function ($$v) {_vm.$set(_vm.form.faq, "isSameLanguage", $$v)},expression:"form.faq.isSameLanguage"}},[_vm._v("ใช้เหมือนกันทุกภาษา")])],1)]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},_vm._l((_vm.languageList),function(language,index){return _c('b-button',{key:index,staticClass:"btn btn-language",class:[_vm.languageActive == language.id ? 'active' : ''],attrs:{"type":"button","disabled":_vm.form.faq.isSameLanguage ? true : false},on:{"click":function($event){return _vm.changeLanguage(language.id, index)}}},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(language.nation))])])}),1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',_vm._l((_vm.form.faq.translationList),function(item,index){return _c('div',{key:index},[_c('div',{class:[
                  _vm.languageActive == item.languageId ? '' : 'd-none',
                ]},[_c('InputText',{attrs:{"textFloat":"คำถาม","placeholder":"คำถาม","type":"text","name":"question","img":_vm.imageLogoLang,"isRequired":"","isValidate":_vm.$v.form.faq.translationList.$each.$iter[index].question
                      .$error,"v":_vm.$v.form.faq.translationList.$each.$iter[index].question},model:{value:(item.question),callback:function ($$v) {_vm.$set(item, "question", $$v)},expression:"item.question"}})],1)])}),0)],1),_c('b-row',[_c('b-col',_vm._l((_vm.form.faq.translationList),function(item,index){return _c('div',{key:index},[_c('div',{class:[
                  _vm.languageActive == item.languageId ? '' : 'd-none',
                ]},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('TextEditor',{attrs:{"textFloat":"คำอธิบาย","rows":8,"value":item.answer,"name":'description_' + item.languageId,"img":_vm.imageLogoLang,"placeholder":"Type something...","isRequired":"","v":_vm.$v.form.faq.translationList.$each.$iter[index].answer,"imgTypeId":_vm.imgTypeId},on:{"onDataChange":(val) => (item.answer = val)}})],1)],1)],1)])}),0)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{attrs:{"textFloat":"ลำดับการแสดง","placeholder":"ลำดับการแสดง","type":"text","name":"sortOrder","img":_vm.imageLogoLang,"isRequired":"","isValidate":_vm.$v.form.faq.sortOrder.$error,"v":_vm.$v.form.faq.sortOrder},on:{"onKeypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.form.faq.sortOrder),callback:function ($$v) {_vm.$set(_vm.form.faq, "sortOrder", $$v)},expression:"form.faq.sortOrder"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-bold main-label"},[_vm._v(" สถานะการแสดงผล "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',[_c('div',[_c('b-form-checkbox',{staticClass:"radio-active",attrs:{"switch":"","size":"lg"},model:{value:(_vm.form.faq.enabled),callback:function ($$v) {_vm.$set(_vm.form.faq, "enabled", $$v)},expression:"form.faq.enabled"}},[_c('span',{staticClass:"ml-2 main-label"},[_vm._v(_vm._s(_vm.form.faq.enabled ? "ใช้งาน" : "ไม่ใช้งาน"))])])],1)])])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"md":"6"}},[(_vm.isEdit)?_c('b-button',{staticClass:"btn btn-danger btn-details-set mr-md-2",attrs:{"disabled":_vm.isDisable},on:{"click":function($event){return _vm.openModalDelete(_vm.form.faq.translationList[0].question)}}},[_vm._v("ลบ")]):_vm._e(),_c('router-link',{attrs:{"to":{
                path: '/faq' + this.path + '/details/' + this.faqTopicId,
              }}},[_c('b-button',{staticClass:"btn-details-set btn-cancel",attrs:{"disabled":_vm.isDisable}},[_vm._v("ย้อนกลับ")])],1)],1),_c('b-col',{staticClass:"text-sm-right",attrs:{"md":"6"}},[_c('button',{staticClass:"btn btn-main btn-details-set ml-md-2 text-uppercase",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.checkForm(0)}}},[_vm._v(" บันทึก ")]),_c('button',{staticClass:"btn btn-main btn-details-set ml-md-2 text-uppercase",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.checkForm(1)}}},[_vm._v(" บันทึกและออก ")])])],1)],1):_vm._e(),_c('ModalAlert',{ref:"modalAlert",attrs:{"text":_vm.modalMessage}}),_c('ModalAlertError',{ref:"modalAlertError",attrs:{"text":_vm.modalMessage}}),_c('ModalAlertConfirm',{ref:"isModalAlertConfirm",attrs:{"msg":"ยืนยันการลบ ?","text":_vm.modalMessage,"btnConfirm":"ลบ","colorBtnConfirm":"danger","btnCancel":"ยกเลิก"},on:{"confirm":_vm.btnDelete}}),_c('ModalLoading',{ref:"modalLoading",attrs:{"hasClose":false}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }